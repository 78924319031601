<template>
  <div>
    <v-container fluid>
      <v-card class="transparent" flat>
        <v-card-text class="text-center">

          <img :src="require('@/assets/404.svg')"
               :width="$vuetify.breakpoint.mobile ? '200' : '400'"
               alt="">

          <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-4">
            OOPS Page non trouvée !
          </h1>

          <p>
            Nous n'avons pas trouvé la page que vous recherchez
          </p>

          <v-btn color="primary" depressed to="/">Accueil</v-btn>

        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>

export default {}
</script>

<style scoped>

</style>